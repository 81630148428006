/* You can add global styles to this file, and also import other style files */
/* Provide sufficient contrast against white background */
code {
  color: #e01a76;
}

a {
  text-decoration: none;
}

li {
  list-style-type: none;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.float-left {
  float: left;
}

.col-space-3 {
  margin-left: 3px;
}

.col-space-5 {
  margin-left: 5px;
}

.col-space-10 {
  margin-left: 10px;
}

.col-space-15 {
  margin-left: 15px;
}

.line-space-10 {
  margin-top: 10px;
}

.line-space-2 {
  margin-top: 2px;
}

.line-space-3 {
  margin-top: 3px;
}

.line-space-20 {
  margin-top: 20px;
}

.line-space-30 {
  margin-top: 30px;
}

.line-space-35 {
  margin-top: 35px;
}

.line-space-40 {
  margin-top: 40px;
}

.line-space-bottom-10 {
  margin-bottom: 10px;
}

.text-bold {
  font-weight: bold;
}

.text-italic {
  font-style: italic;
}

.checklist-icon-color {
  color: #1b6ec2;
}

.resize-none {
  resize: none;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-bottom-0 {
  margin-bottom: 0px;
}

.width-32 {
  width: 32px;
}

.vertical-align-center {
  vertical-align: middle !important;
}

/* Tile section start */
.tile-group {
  display: inline-block;
  float: none;
}

  .tile-group li {
    float: left;
    margin: 10px 0px 0px 10px;
  }

.tile {
  height: 150px;
  width: 150px;
  text-align: center;
  color: #ffffff;
  background-color: #1b6ec2;
  cursor: pointer;
  border-radius: 20px;
}

.tile-icon {
  font-size: 76px;
  max-height: 100px;
}

.tile-sub {
  height: 50px;
  background-color: rgba(0, 0, 0, 0.60);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.table-slim-margin, td, tr, th {
  padding: 0.35rem !important;
  vertical-align: middle !important;
}

/* Tile section end */

.link-default {
  color: #2957A5 !important;
  font-weight: bold;
  cursor: pointer;
}

.error-text {
  color: #bf0000;
}

.clickable-area {
  cursor: pointer;
}

.select-table-line tr:hover {
  cursor: pointer;
  color: white;
  background-color: #1b6ec2 !important;
}

left-dividing-line {
  border-left: solid 1px;
  border-left-color: #ccc !important;
}

.internal-list-card {
  width: 100%;
  height: 100%;
  min-height: 110px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border: solid 1px;
  border-color: #ccc;
  border-left: none;
  border-bottom-width: 3px;
}

.external-list-card {
  width: 100%;
  min-height: 110px;
  margin-bottom: 10px;
  border: solid;
  border-top: none;
  border-right: none;
  border-bottom: none;
  border-left-width: 30px;
}

.item-ok {
  margin-left: 10px;
  margin-right: 10px;
  background-color: #05a705;
  color: white;
  padding: 3px;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 700;
}

.item-not-ok {
  margin-left: 10px;
  margin-right: 10px;
  background-color: #f31515;
  color: white;
  padding: 3px;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 700;
}

.checklist-category {
  width: 100%;
  background-color: #1b6ec2;
  padding: 5px 5px;
  color: #fff;
  font-weight: bold;
}

.customer-header-title {
  background-color: #007bff;
  color: #ffffff;
  padding: 3px;
  margin-bottom: 0px;
  font-weight: bold;
  padding-left: 5px;
  padding-right: 5px;
}

.customer-bottom {
  background-color: #007bff;
  color: #ffffff;
  padding: 2px;
  margin-bottom: 0px;
  margin-top: 0px;
}

.customer-equipment-list {
  margin-left: -35px;
  margin-top: 10px;
}

.equipment-item-list {
  margin-left: -39px;
  margin-bottom: 5px;
}

.equipment-item-title {
  font-weight: 500;
  color: #4e4e4e;
  margin: 0px;
}

.card-list-default {
  width: 100%;
  height: 100%;
  min-height: 65px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border: solid 1px;
  border-color: #ccc;
  border-bottom-width: 3px;
  margin-top: 10px;
}

.icon-check-circle {
  color: #007bff;
  float: right;
  font-size: 25px;
}

.small-link {
  font-style: italic;
  font-size: 15px;
  color: #007bff;
}

.bottom-line-checklist {
  border-bottom: solid;
  border-bottom-width: 5px;
  border-bottom-color: #b7b7b7;
  margin-bottom: 10px;
}

.width-full {
  width: 100%;
}

.hover-none {
  pointer-events: none;
}

/* --- Colors of status card --- */

.yellow-tag {
  border-left-color: #FFD700;
}

.yellow-tag-td {
  color: #212529 !important;
  background-color: #FFD700;
}

.orange-tag {
  border-left-color: #FF8000;
}

.orange-tag-td {
  color: #212529 !important;
  background-color: #FF8000;
}

.light-blue-tag {
  border-left-color: #00BFFF;
}

.light-blue-tag-td {
  color: #212529 !important;
  background-color: #00BFFF;
}

.red-tag {
  border-left-color: #FF0000;
}

.red-tag-td {
  color: #212529 !important;
  background-color: #FF0000;
}

.gray-tag {
  border-left-color: #848484;
}

.gray-tag-td {
  color: #212529 !important;
  background-color: #848484;
}

.lilac-tag {
  border-left-color: #BF00FF;
}

.lilac-tag-td {
  color: #212529 !important;
  background-color: #BF00FF;
}

.beige-tag {
  border-left-color: #F9EAC3;
}

.beige-tag-td {
  color: #212529 !important;
  background-color: #F9EAC3;
}

.dark-green-tag {
  border-left-color: #088A08;
}

.dark-green-tag-td {
  color: #212529 !important;
  background-color: #088A08;
}

.light-green-tag {
  border-left-color: #01DF01;
}

.light-green-tag-td {
  color: #212529 !important;
  background-color: #01DF01;
}

.red-background-color {
  background-color: #ff0000;
  color: #ffffff;
  font-weight: bold;
}

.orange-background-color {
  background-color: #ffa500;
  font-weight: bold;
  /*color: #ffffff;*/
}

.dark-green-background-color {
  background-color: #006400;
  color: #ffffff;
  font-weight: bold;
}

.light-green-background-color {
  background-color: #03f303;
  font-weight: bold;
  /*color: #ffffff;*/
}

.grey-background-color {
  background-color: #808080;
  color: #ffffff;
  font-weight: bold;
}

.yellow-background-color {
  color: #212529 !important;
  background-color: #FFD700;
  font-weight: bold;
}

/* --- End colors of status card --- */

/* --- Start Input File --- */

.file-input {
  display: block;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  cursor: pointer;
}

/* --- End Input File --- */

